/* eslint-disable import/order */
import { Core } from "@griddo/core";

// ui schemas
import components from "./src/schemas/components";
import modules from "./src/schemas/modules";
import templates from "./src/schemas/templates";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	richText,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categoryContentTypes,
	pageContentTypes,
	simpleContentTypes,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacks, dataPacksCategories } from "./src/schemas/data-packs";

export default {
	schemas: {
		config: {
			menus,
			themes,
			languages,
			menuItems,
			moduleCategories,
			richTextConfig: richText,
		},

		ui: {
			components,
			modules,
			templates,
		},

		contentTypes: {
			dataPacks,
			dataPacksCategories,
			structuredData: {
				...categoryContentTypes,
				...pageContentTypes,
				...simpleContentTypes,
			},
		},
	},
} satisfies Core.Config;

const idFromYoutubeUrl = (url: string) => {
	const removeParams = url?.split("&")[0];
	const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
	const match = removeParams?.match(regExp);
	if (match && match[2].length === 11) {
		return match[2];
	}
	return null;
};

const idFromVimeoUrl = (url: string) => {
	const regExp =
		/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;
	const match = url?.match(regExp);
	if (match && match[1]) {
		return match[1];
	}
	return null;
};

export const getVideoSrc = (href: string | undefined, autoplay?: boolean) => {
	let source;

	if (href) {
		const isYoutubeVideo =
			href?.includes("youtube") || href?.includes("youtu.be");
		const isVimeoVideo =
			href?.includes("player.vimeo.com/external") || href?.includes("vimeo");

		if (isYoutubeVideo && href) {
			source = `//www.youtube-nocookie.com/embed/${idFromYoutubeUrl(href)}`;

			if (autoplay) {
				source +=
					"?autoplay=1&mute=1&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
			} else {
				source += "?&autoplay=0&controls=1&mute=0";
			}
		}

		if (isVimeoVideo && href) {
			source = `https://player.vimeo.com/video/${idFromVimeoUrl(href)}`;

			if (autoplay) {
				source += "?autoplay=1&controls=0&muted=1&loop=1";
			}
		}
	}

	return source || href;
};

export const getVideoOptions = (subtitles = false, language?: string) => {
	return {
		youtube: {
			playerVars: {
				cc_load_policy: subtitles ? 1 : 0,
				cc_lang_pref: language,
			},
		},
		vimeo: {
			playerOptions: {
				texttrack: language,
			},
		},
	};
};

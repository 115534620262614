import AutoTypes from "@autoTypes";

export const getVcard = async ({
	name,
	positionLabel,
	email,
	phone1,
	phone2,
	office,
	region,
	linkedin,
	image,
}: Pick<
	AutoTypes.PeopleDetailProps,
	"positionLabel" | "phone1" | "phone2" | "linkedin" | "email"
> & {
	name?: string;
	office?: string;
	region?: string;
	image?: string;
}) => {
	let vCard = `BEGIN:VCARD
VERSION:3.0
N:${name};;;
FN:${name}
ORG:ECIJA;`;

	if (positionLabel) {
		vCard = `${vCard}
TITLE:${positionLabel}`;
	}

	if (email) {
		vCard = `${vCard}
EMAIL;type=INTERNET;type=WORK;type=pref:${email}`;
	}

	if (phone1) {
		vCard = `${vCard}
TEL;PID=1.1;type=WORK;type=VOICE;type=pref:${phone1}`;
	}

	if (phone2) {
		vCard = `${vCard}
TEL;PID=2.1;type=WORK;type=VOICE;type=pref:${phone2}`;
	}

	if (office || region) {
		vCard = `${vCard}
ADR;type=WORK:;${office || ""};${region || ""};`;
	}

	if (linkedin) {
		vCard = `${vCard}
NOTE:LinkedIn: ${linkedin}`;
	}

	if (image) {
		try {
			const base64 = await fetch(image)
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);

					return new Promise((res) => {
						reader.onloadend = () => {
							res(reader.result);
						};
					});
				});

			if (typeof base64 === "string") {
				vCard = `${vCard}
PHOTO;ENCODING=b:${base64.split(",").pop()}`;
			}
		} catch (error) {
			/* empty */
		}
	}

	return `${vCard}
URL;type=WORK;type=pref:ecija.com/
END:VCARD`;
};

/**
 * Returns an array with unique elements.
 * @example
 * uniqueArray([1, 1, 1, 33]) // Array<number>
 * // -> [1, 33]
 */
export function uniqueArray<T>(array: Array<T>) {
	return [...new Set(array)];
}

/**
 * Return an array of value props from an array of objects
 * @example
 * getPropsFromArrayOfObjects({ array: [{a: 1}, {a: 2}], prop. "a") // Array<number>
 * // -> [1, 2]
 */
export function getPropsFromArrayOfObjects<T, K extends keyof T>(props: {
	array: Array<T>;
	prop: K;
	unique?: boolean;
}) {
	const { array, prop, unique = false } = props;
	const properties = array.map((obj) => obj[prop]);

	return unique ? uniqueArray(properties) : properties;
}

export const groupArray = <T>(
	data: Array<T>,
	groups: number,
): Array<Array<T>> => {
	const output: Array<Array<T>> = [];
	const length = data.length;
	let i = 0;
	let size;
	let groupNumber = groups;

	if (length % groupNumber === 0) {
		size = Math.floor(length / groupNumber);
		while (i < length) {
			output.push(data.slice(i, (i += size)));
		}
	} else {
		while (i < length) {
			size = Math.ceil((length - i) / groupNumber--);
			output.push(data.slice(i, (i += size)));
		}
	}

	return output;
};

import { Theme } from "@griddo/core";

import globalTheme from "./__global__";
import ecijaTheme from "./ecija";
import pistachioTheme from "./pistachio";
import plumTheme from "./plum";
import { themes } from "../schemas/config/themes";

export const subthemes = ["default", "default-alt", "inverse"] as const;
export type Subthemes = (typeof subthemes)[number];
export type Themes = (typeof themes)[number]["value"];

const griddoTheme: Theme.GriddoTheme = {
	// Global theme definition is designed to be used generically across all the
	// sites and the whole instance design system.
	global: globalTheme,

	// Themes are designed to be used by one or many instance sites.
	themes: [ecijaTheme, pistachioTheme, plumTheme],
};

export default griddoTheme;

import { Schema } from "@griddo/core";

export const PEOPLE: Schema.PageContentType = {
	dataPacks: ["PEOPLE"],
	title: "People",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["PeopleDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
				mandatory: true,
			},
			{
				type: "TextField",
				title: "Position label",
				from: "positionLabel",
				key: "positionLabel",
				mandatory: true,
			},
			{
				type: "TextField",
				title: "Custom order",
				from: "customOrder",
				key: "customOrder",
				indexable: true,
			},
			{
				type: "TextField",
				title: "Phone 1",
				from: "phone1",
				key: "phone1",
			},
			{
				type: "TextField",
				title: "Phone 2",
				from: "phone2",
				key: "phone2",
			},
			{
				type: "TextField",
				title: "LinkedIn",
				from: "linkedin",
				key: "linkedin",
			},
			{
				type: "TextField",
				title: "Email",
				from: "email",
				key: "email",
			},
			{
				type: "MultiCheckSelect",
				title: "Practice areas",
				placeholder: "Practice areas",
				from: "categories.areas",
				key: "areas",
				source: "PRACTICE_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Sectors",
				placeholder: "Sectors",
				from: "categories.sectors",
				key: "sectors",
				source: "SECTOR",
			},
			{
				type: "MultiCheckSelect",
				title: "Office",
				placeholder: "Office",
				from: "categories.office",
				key: "office",
				source: "OFFICES",
			},
			{
				type: "MultiCheckSelect",
				title: "Position",
				placeholder: "Position",
				from: "categories.position",
				key: "position",
				source: "POSITION",
			},
			{
				type: "MultiCheckSelect",
				title: "Region",
				placeholder: "Region",
				from: "categories.region",
				key: "region",
				source: "REGION",
			},
			{
				type: "AsyncSelect",
				key: "charAt",
				from: "charAt",
				title: "CharAt",
				source: "CHARAT",
			},
		],
	},
};

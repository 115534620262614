import { Schema } from "@griddo/core";

export const CHARAT: Schema.CategoryContentType = {
	dataPacks: ["PEOPLE"],
	title: "CHARAT",
	local: false,
	taxonomy: true,
	fromPage: false,
	translate: false,
	editable: true, // remove
};

import {
	SiteProvider,
	type Schema,
	type SiteProviderProps,
} from "@griddo/core";
import { Suspense } from "react";

// Static translations
import { translations } from "./src/translations";

// Custom CSS
import "./src/global-css/custom/global.css";
import "./src/global-css/custom/normalize.css";
import "./src/global-css/custom/text-styles.css";
import "./src/global-css/custom/utils.css";

// Themes CSS
import "./src/global-css/themes/global.css";
import "./src/global-css/themes/ecija.css";
import "./src/global-css/themes/pistachio.css";
import "./src/global-css/themes/plum.css";

// Grisso CSS
import "./src/global-css/grisso/grisso.min.css";

// Griddo DAM image settings
const DAMDefaults: Schema.DamDefaults = {
	quality: 75,
	crop: "cover",
	loading: "lazy",
	decoding: "async",
	formats: ["webp"],
};

// A wrapper for the Griddo <SiteProvider> with custom instance props.
// This is a great place to add site contexts like theme providers etc.
function CustomSiteProvider({ children, ...rest }: SiteProviderProps) {
	return (
		<SiteProvider
			griddoDamDefaults={DAMDefaults}
			translations={translations}
			{...rest}
		>
			<div
				id="___griddo"
				// Allows scrolling inside the Griddo editor preview area.
				style={{ minHeight: "100svh" }}
			>
				<Suspense fallback={null}>{children}</Suspense>
			</div>
		</SiteProvider>
	);
}

export { CustomSiteProvider as SiteProvider };

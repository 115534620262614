import { getUtmParams } from "@helpers";
import components from "@ui/components";
import modules from "@ui/modules";
import templates from "@ui/templates";

import browser from "../builder.browser";
import ssr from "../builder.ssr";
import { SiteProvider } from "../griddo.root";

// Prepare components
const bundle = { ...components, ...modules };

// Dimensions
const generateAutomaticDimensions = (page) => {
	const page_section =
		page.pageContext?.page?.fullPath?.page.split("/")[1] || "";
	const page_name = page.pageContext?.page?.title;
	const page_type = page.pageContext?.page?.templateId;

	const utm = getUtmParams();

	const dimensions = {
		event: "custom_event",
		event_name: "page_view",
		page_section,
		page_name,
		page_type,
		...utm,
		...(page.pageContext?.page?.dimensions?.values || {}),
	};

	if (typeof window !== "undefined" && window.location.hash) {
		dimensions.anchor = window.location.hash.replace("#", "");
	}

	if (typeof window !== "undefined") {
		return dimensions;
	}
};

// Final exports
export {
	browser,
	bundle as components,
	generateAutomaticDimensions,
	SiteProvider,
	ssr,
	templates,
};

import { Schema } from "@griddo/core";

// Lets TypeScript do them type inferring for the `themes` to extract theme
// values as union type. Otherwise is kind of impossible due to the explicit
// Type `Schema.Themes`
const themes: Schema.Themes = [
	{
		default: true,
		label: "ECIJA theme",
		value: "ecija",
	},
	{
		label: "Pistachio theme",
		value: "pistachio",
	},
	{
		label: "Plum theme",
		value: "plum",
	},
];

const schema = themes;

export { themes };
export default schema;

export interface partialEventType {
	page_section?: string;
	page_name?: string;
	page_type?: string;
	form_id?: string;
	videoUrl?: string;
	file_name?: string;
	module_name?: string;
	hero_cta?: string;
	search_term?: string;
	search_result_count?: string;
	time_spent?: number;
}

interface eventType extends partialEventType {
	event: string;
	event_name: string;
}

export const addDataLayer = (event: eventType) => {
	if (!window) {
		return;
	}
	if (!(window as any).dataLayer) {
		(window as any).dataLayer = [];
	}

	(window as any).dataLayer.push(event);
};

export const getUtmParams = () => {
	const utm: { [key: string]: string } = {};

	try {
		const urlParams = new URLSearchParams(window.location.search);

		for (const [key, value] of urlParams) {
			if (key.indexOf("utm_") === 0 && value) {
				utm[key] = value;
			}
		}
	} catch (error) {
		/* empty */
	}

	return utm;
};

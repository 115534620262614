import { HeaderProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { defaultLinkLite, heading, linkLite } from "@schemas/presets";

const schema: Schema.Header<HeaderProps> = {
	schemaType: "module",
	displayName: "Header",
	component: "Header",
	type: "header",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
				},
				{
					type: "RadioGroup",
					title: "Language selector",
					key: "languageSelector",
					hideable: true,
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
				},
				{
					key: "search",
					title: "Search feature",
					type: "ConditionalField",
					options: [
						{ name: "yes", title: "Yes", value: true },
						{ name: "no", title: "No", value: false },
					],
					fields: [
						{
							condition: true,
							...heading,
							key: "searchTitle",
							mandatory: false,
							isMockup: false,
						},
						{
							condition: true,
							title: "Subtitle",
							key: "subtitle",
							type: "TextField",
							placeholder: "Type a subtitle",
							hideable: true,
						},
						{
							condition: true,
							title: "Search Result page",
							type: "UrlField",
							key: "searchLink",
						},
					],
				},
				{ ...linkLite },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as default",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Select main menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
				},
				{
					title: "Select offices menu",
					key: "officesMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
				},
				{
					type: "UniqueCheck",
					key: "sticky",
					title: "Keep sticky on scroll",
					options: [{ title: "Keep sticky on scroll" }],
				},
			],
		},
	],

	default: {
		component: "Header",
		type: "header",
		title: "New header",
		search: true,
		subtitle: "Encuéntralo en ECIJA?",
		searchTitle: { content: "¿Qué buscas?", tag: "h2" },
		languageSelector: true,
		link: {
			...defaultLinkLite,
		},
		setAsDefault: true,
		menu: undefined,
		officesMenu: undefined,
		sticky: true,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Header/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Header/thumbnail@1x.png",
	},
};

export default schema;

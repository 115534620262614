import { PeopleDetailProps, CHARATContentTypeProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { slugify } from "@helpers/helpers";
import Quote from "@schemas/modules/Quote";
import { content, loremIpsumParagraph } from "@schemas/presets";

type ItemChartAt = Pick<CHARATContentTypeProps<true, true>, "code"> & {
	id: number;
};

export type Letters = {
	page: number;
	totalItems: number;
	items: Array<ItemChartAt>;
};

const schema: Schema.Template<PeopleDetailProps> = {
	schemaType: "template",
	component: "PeopleDetail",
	displayName: "People",
	dataPacks: ["PEOPLE"],

	type: {
		label: "People",
		value: "People",
		mode: "detail",
	},

	content: [
		{
			type: "TextField",
			title: "Professional name",
			key: "professionalName",
			mandatory: true,
			isMockup: true,
			helptext:
				"Write in plain text with capital letters and select the type of heading. Use underscores at the beginning and end of a word to highlight it. For example: Lorem _ipsum_",
		},
		{
			type: "ImageField",
			key: "image",
			title: "Image",
			mandatory: true,
		},
		{
			key: "positionLabel",
			title: "Position label",
			type: "TextField",
			mandatory: true,
		},
		{
			key: "hierarchy",
			title: "Hierarchy",
			type: "Select",
			options: [
				{ value: "10", label: "Leadership team" },
				{ value: "20", label: "Managing partner" },
				{ value: "30", label: "Partner" },
				{ value: "40", label: "Manager" },
				{ value: "50", label: "Senior associate" },
				{ value: "60", label: "Associate" },
			],
			mandatory: true,
		},
		{
			key: "customOrder",
			title: "Custom order",
			type: "TextField",
			computed: (page) => {
				return `${page?.template.hierarchy || 60}${page?.title}`;
			},
			readonly: true,
			hidden: true,
		},
		{
			key: "phone1",
			title: "Phone 1",
			type: "TextField",
			hideable: true,
		},
		{
			key: "phone2",
			title: "Phone 2",
			type: "TextField",
			hideable: true,
		},
		{
			type: "TextField",
			title: "LinkedIn",
			key: "linkedin",
			hideable: true,
			validators: { format: "URL" },
		},
		{
			type: "TextField",
			title: "Email",
			key: "email",
			hideable: true,
			validators: { format: "email" },
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Intro",
			key: "intro",
			mandatory: true,
			isMockup: true,
			humanReadable: true,
		},
		{ ...content, mandatory: true, hideable: false },
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Practice areas",
					key: "areas",
					source: "PRACTICE_AREA",
				},
				{
					placeholder: "Sectors",
					key: "sectors",
					source: "SECTOR",
				},
				{
					placeholder: "Office",
					key: "office",
					source: "OFFICES",
				},
				{
					placeholder: "Position",
					key: "position",
					source: "POSITION",
				},
				{
					placeholder: "Region",
					key: "region",
					source: "REGION",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
		{
			type: "TextField",
			key: "lastModified",
			title: "Last modified",
			computed: () => new Date().toJSON(),
			readonly: true,
		},
		{
			type: "AsyncSelect",
			key: "charAt",
			title: "CharAt",
			source: "CHARAT",
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			computed: async (
				page: {
					canonicalSite: number;
					language: number;
					template: PeopleDetailProps;
				},
				options: {
					operation: "save" | "load";
					publicApiUrl: string;
				},
			) => {
				const { operation, publicApiUrl } = options;
				let charAtId = page.template.charAt || null;

				if (operation === "save") {
					const { canonicalSite, template } = page;

					const fetchLetters = async (): Promise<Letters | undefined> => {
						try {
							const baseURL = new URL(
								`/list/v2/CHARAT/site/${canonicalSite}/page/1/items/100/lang/null`,
								publicApiUrl,
							);

							const response = await fetch(baseURL.href);
							if (!response.ok) {
								// eslint-disable-next-line no-console
								console.error(`Error en fetch: ${response.status}`);
								return undefined;
							}

							return await response.json();
						} catch (error) {
							// eslint-disable-next-line no-console
							console.error("computed-error", error);
							return undefined;
						}
					};

					const data = await fetchLetters();

					const charAt = template.professionalName?.charAt(0)?.toLowerCase();
					if (data?.items) {
						const match = data.items.find(
							(item) => item.code === slugify(charAt),
						);
						charAtId = match?.id || charAtId;
					}
				}

				return charAtId;
			},
		},
	],

	default: {
		type: "template",
		templateType: "PeopleDetail",
		professionalName: "Lorem ipsum",
		intro: loremIpsumParagraph,
		content: loremIpsumParagraph,
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...Quote.default }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PeopleDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PeopleDetail/thumbnail@2x.png",
	},
};

export default schema;
